.ant-picker-cell-inner {
  width: 100% !important;
  height: 100% !important;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 40px; */
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events li {
  display: flex;
  justify-content: center;
}

.ant-picker-cell-inner.today {
  color: #1890ff;
}

.today::before {
  /* content: "";
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-radius: 50%;
  position: absolute !important;
  top: 0; */
  /* Posición de la bolita */
}

.ant-picker-calendar-date {
  padding-top: 20px;
}

.ant-picker-calendar-date-content {
  position: relative !important;
}

.ant-picker-calendar-date-content .today {
  position: absolute !important;
}

.ant-picker-calendar-date-content .today::before {

  /* place-self: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  inset-inline-start: 0;
  z-index: 1;
  border: 1px solid #0778B1;
  border-radius: 100% !important;
  content: "";
  width: 15px !important;
  height: 15px !important; */
  border: none !important;
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  /* background: red; */
  display: inline-block;
  border-radius: 100%;
  place-self: center;
  top: -40px;
  bottom: none !important;
  background-color: #0778B1;
}

.ant-picker-cell-selected .ant-picker-calendar-date {
  width: 35px !important;
  height: 35px !important;
  border-radius: 100% !important;
}

.ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value {
  position: absolute;
  top: 6px;
  display: flex;
  left: 9px;
}

.ant-picker-cell-today .ant-picker-calendar-date{
  background-color: transparent !important;
  color: black !important;
}

.ant-picker-cell-in-view{
  width: 35px !important;
  height: 35px !important;
}

#IconRight-Calendar{
  text-align: -webkit-right;
}