/* src/styles/pages/offers/Answers.css */

.answers-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  
  .answers-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .answers-details {
    flex-grow: 1;
    margin-left: 20px;
  }
  
  .answers-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .answers-divider {
    margin: 0;
  }
  