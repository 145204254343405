@tailwind base;
@tailwind components;
@tailwind utilities;

.p-4 {
  padding: 0rem;
}

.p-2 {
  border-radius: 6px;
}

.ant-card-body {
  width: 100% !important;
}

.text-truncate .ant-typography-expand span, .text-truncate .ant-typography-collapse span{
  color: #006497 !important;
} 

.text-truncate .ant-typography-expand span:hover, .text-truncate .ant-typography-collapse span:hover{
  color: #4096ff !important;
} 