.GraphicUsers .recharts-wrapper{
    display: flex;
    justify-content:end;
}
.GraphicUsers .recharts-layer{
    font-family: 'Inter';
    font-size:10px;
    font-weight: 400;
    margin-top:10px;
    margin-bottom:10px;
}

.GraphicUsers .recharts-polar-angle-axis-tick{
    opacity:0.8;
}