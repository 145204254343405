.button-cancel{
    border: 1px solid #E1E1E2;
    box-shadow: 1px 1px 2px 0px rgba(26, 26, 26, 0.08);
}

.button-accept.active:hover{
    background-color: #1C82BC !important;
    color: #FFFFFF !important;
}

.button-cancel:hover{
    border: 1px solid #E1E1E2 !important;
    color: #1A1A1A !important;
}

.modal-puntos .ant-modal-content{
    border-radius: 12px !important;
}