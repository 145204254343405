.Input-Filter-Employment,
.Input-Filter-Employment:hover,
.Input-Filter-Employment:focus {
    height: 44px;
    border: 1px solid #006497;
}

.custom-placeholder {
    font-size: 16px;
    font-weight: 500; /* 'medium' */
    font-family: 'Inter', sans-serif;
    color: red;
}  

/* Estilos para los inputs */
.Input-Filter-Employment:hover {
    background-color: #eef0f0; /* Gris un poco más oscuro */
    border: 2px solid #006497 !important; /* Borde más grueso */
    color: black !important; /* Letra color negro */
}

.Input-Filter-Employment:focus,
.Input-Filter-Employment.clicked {
    background-color: #fff; /* Fondo original */
    border: 2px solid #81BFEC !important; /* Borde más grueso y de color #81BFEC */
}

.Select-Filter-Employment,
.Select-Filter-Employment:hover,
.Select-Filter-Employment:focus {
    border: 1px solid #006497;
    height: 44px;
    border-radius: 4px;
}

/* Estilos para los inputs */
.Select-Filter-Employment:hover {
    background-color: #e0e0e0; /* Gris un poco más oscuro */
    border: 2px solid #006497 !important; /* Borde más grueso */
    font-weight: bold; /* Texto en negrita */
}

.Select-Filter-Employment:focus,
.Select-Filter-Employment.clicked {
    background-color: #fff; /* Fondo original */
    border: 2px solid #81BFEC !important; /* Borde más grueso y de color #81BFEC */
}

.Button-Filter-Employment,
.Button-Filter-Employment:hover,
.Button-Filter-Employment:active {
    background: #006497;
    height: 44px;
    border-radius: 4px;
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    align-content: center;
    text-align: center;
    cursor: pointer;
}

/* Estilos para el botón */
.Button-Filter-Employment:hover {
    background-color: #0078A8 !important; /* Azul ligeramente más claro */
    border-color: #81BFEC !important; /* Borde del color #81BFEC */
    color: white !important;
}

.Button-Filter-Employment:active {
    background-color: #006497 !important; /* Vuelve al color original */
    box-shadow: 0 0 0 4px #81BFEC !important; /* Borde de color #81BFEC por fuera */
    border-color: #81BFEC !important; /* Cambiar borde al color #81BFEC */
}