.card-register-candidate:hover {
  background: #006497 !important;
  color: white !important;
}

.card-register-candidate:hover h1 {
  color: white !important;
}

.card-register-candidate:hover p {
  color: white !important;
}

.card-register-candidate:hover img {
  color: white !important;
}