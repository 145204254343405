.button-stats{
    height: auto;
    /* height: 26px !important; */
    border: 1px solid #E1E1E2 !important;
    color: #1A1A1A !important;
}

.button-stats:hover{
    border: 1px solid #E1E1E2 !important;    
    color: #1A1A1A !important;
    background-color: #FCFCFC !important;
}
