.button-container.flex-row {
  flex-direction: row;
}

.button-container.flex-col {
  flex-direction: column;
}

.button-container .ant-btn {
  width: 100%; /* Full width for the button */
  /* justify-content: left; */
  padding: 12px 16px; /* Padding to match the style in the image */
  border-radius: 4px;
  margin-bottom: 10px; /* Margin between buttons */
}


/* Estilos para asegurarse de que el contenedor se ajuste al contenido */
.help-custom-button-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.help-custom-button .ant-btn {
  width: 100%;
  border-radius: 6px !important;
  height: 38px !important;
  margin-left: 0%;
}
.help-custom-button-container {
  overflow: hidden;
}
