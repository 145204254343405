.button-unlocked:hover{
    /* background-color: red !important; */
    background-color: #1C82BC !important;
    border: 1px solid #006497 !important;
}

.button-unlocked:active {
    background-color: #006497 !important; /* Vuelve al color original */
    box-shadow: 0 0 0 4px #81BFEC !important; /* Borde de color #81BFEC por fuera */
    border-color: #81BFEC !important; /* Cambiar borde al color #81BFEC */
}